<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Client Session Validation</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row listing">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                                </div>


                                <div class="form-group mr-1 mt-3 p-1">
                                    <p>&#9866;</p>
                                </div>
                                <div class="form-group mr-1">
                                    <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                                </div>

                                <!--                            <div class="form-group mr-1">-->
                                <!--                                <select class="form-control" v-model="filter.domain">-->
                                <!--                                    <option selected :value="undefined">-&#45;&#45;Select Domain-&#45;&#45;</option>-->
                                <!--                                    <option v-for="item in domain" :key="item" :value="item.domain">{{item.domain}}</option>-->
                                <!--                                </select>-->
                                <!--                            </div>-->

                                <div class="form-group mr-1">
                                    <input type="text" v-model="filter.domain" class="form-control" placeholder="Domain"/>
                                </div>


                                <div class="form-group mr-1  pl-2">
                                    <select class="form-control" v-model="filter.dimension">
                                        <option selected :value="undefined">---Secondary Dimension---</option>
                                        <option  value="hour">Hour</option>
                                        <option  value="country">Country</option>
                                        <option  value="state">State</option>
                                        <option  value="city">City</option>
                                    </select>
                                </div>

                                <button type="button"  @click="load" class="btn searchBtn text-white mr-1" style="background-color: #383838"><i class="fa fa-search" title="Search"></i> <span>Search </span></button>
                                <button type="button" @click="excel"  class="btn btn-success">Export</button>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
            <div class="row">

                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="item"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :allowSorting="true"
                        :allowGrouping='true'
                        :allowFiltering="true"
                        :allowResizing='true'
                        :groupSettings='groupSettings'
                        :filterSettings="filterSettings"
                        :pageSettings='pageSettings'
                        :actionBegin='actionBegin'
                    >
                        <e-columns>
                            <e-column field='domain' headerText='Domain'></e-column>
                            <e-column field='date' headerText='Date'></e-column>
                            <e-column field='hour' headerText='Hour'></e-column>
                            <e-column field='state' headerText='State'></e-column>
                            <e-column field='country' headerText='Country'></e-column>
                            <e-column field='city' headerText='City'></e-column>
                            <e-column field='sessions' headerText='Sessions/Users'></e-column>
                            <e-column field='averageSession' headerText='Average session duration'></e-column>
                            <e-column field='avgBounce' headerText='Average Bounce ' ></e-column>
                            <e-column field='pagesPerSession' headerText='Pages Per Session' ></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>



            </div>
        </div>

    </div>
</template>

<script>

import moment from "moment-timezone";
// import dateFormat from 'dateformat';
import axios from "axios";
import Swal from "sweetalert"
import {
    GridPlugin,
    Page,
    Sort,
    ExcelExport,
    Group,
    Filter,
    Resize
} from "@syncfusion/ej2-vue-grids";
// import VSelect from 'vue-select'
import Vue from "vue";

Vue.use(GridPlugin);

export default {
    name: "sessionValidateReport.vue",
    props:['user'],
    components:{
    },
    data() {
        return {

            domain:[],
            item: [],
            pageNumber:1,
            providers:[],
            page: 0,
            perPage:25,
            pageSettings: {pageSize: 25},
            report:[],
            count: true,
            filter:{
                from:'',
                to:'',
                start: "00:00",
                end: "23:59",
                limit: 1000000,
                dimension:'undefined',


            },

            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            },
            groupSettings:  {
                showDropArea: false,
                disablePageWiseAggregates: true,
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            users: '',
        }
    },

    async created() {

        this.filter.to = moment().format('YYYY-MM-DD');
        this.filter.from = moment().subtract(7,'d').format('YYYY-MM-DD');
        this.getUser()
        this.loadDomains();

        this.$root.preloader = false;



    },

    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize
        ]
    },


    methods: {
        actionBegin: function(args) {
            if(args.requestType === "paging") {
                this.page = args.currentPage;
                console.log('see page yah ')
                console.log(this.page)
                this.load();
            }
        },
        async getUser() {
            let resp = await axios.get(`${this.$root.serverUrl}/admin/profile/`)
            if (resp.data && resp.data.error) {
                console.log(1)
            } else {
                this.users  = resp.data.data
            }
        },

        hide: function () {
            this.$refs.grid.hideColumns(['Country', 'State','City','Hour']);
            if(this.filter.dimension == "country") this.$refs.grid.showColumns(['Country']);
            if(this.filter.dimension == "state" || this.filter.dimension == 'undefined' )  this.$refs.grid.showColumns(['State']);
            if(this.filter.dimension == "city") this.$refs.grid.showColumns(['City']);
            if(this.filter.dimension == "hour") this.$refs.grid.showColumns(['Hour']);
            this.$refs.grid.refresh()
        },
        loadDomains: function () {
            var request = {'apikey': this.$root.apikey, sSearch: ''};

            return axios.get(`${this.$root.serverUrl}/admin/domain/domain/list`, {params: request}).then(function (resp) {
                //Store the stats
                this.domain = (resp.data && resp.data.data) ? resp.data.data : [];
                console.log(this.domain)
                this.hide();
            }.bind(this));
        },

        toHHMMSS : function (value) {
            var string = '';
            var sec_num = parseInt(value, 10); // don't forget the second param
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            // return hours+':'+minutes+':'+seconds;
            string = hours+':'+minutes+':'+seconds
            return string;
        },



        load: async function () {
            this.hide();
            // this.hide();
            try {
                this.$root.preloader = true;
                var request = {'apikey': this.$root.apikey, sSearch: null,max: this.pageSettings.pageSize, page: this.page,};

                request.filters = this.filter
                request.filters.max = this.pageSettings.pageSize
                request.filters.page = this.page
                request.user = this.users
                let response = await axios.get(`${this.$root.serverUrl}/admin/reports/session-validation`, {params: request})

                if (!response.data.data) {

                    this.$root.preloader = false;
                    await Swal('Error!!', response.data.error, 'error')
                    this.$forceUpdate();
                } else {
                    let count = (response.data && response.data.data) ? response.data.count : 0;
                    // let data = (response.data && response.data.data) ? response.data.data : [];

                    this.item = {count: count, result: []}

                    for(const i in response.data.data){
                        this.item.result.push({
                            domain: response.data.data[i].domain,
                            date:moment(response.data.data[i].date).tz(this.users.timezone).format('MM-DD-YYYY'),
                            hour:response.data.data[i].hour,
                            country: response.data.data[i].country,
                            state: response.data.data[i].state,
                            city: response.data.data[i].city,
                            avgBounce : response.data.data[i].avgBounce.toFixed(2) + ' ' + '%',
                            averageSession: this.toHHMMSS(response.data.data[i].averageSession),
                            pagesPerSession: response.data.data[i].pagesPerSession.toFixed(2),
                            sessions: response.data.data[i].sessions
                        })
                    }
                    this.hide();
                    this.$root.preloader = false;
                    this.$forceUpdate();
                }

            } catch (e) {
                console.log(e);
                this.$root.preloader = false;
            }
        },




        excel: async function () {
            console.log('export');
            try {

                let excelExportProperties = {
                    dataSource: this.item,
                    fileName:"Client_Session.xlsx"
                };
                this.$refs.grid.excelExport(excelExportProperties);

                this.$root.preloader = false;
            } catch (e) {
                console.log(e);
                this.$root.preloader = false;
            }
        },

    }
}
</script>

<style scoped>

</style>
